<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="goods-detail">
            <el-col :span="3" style="width: 15.3%"></el-col>
            <el-col :span="17" v-loading="loading">
                <div
                    style="padding: 100px;"
                    v-if="loading && goodsList.length == 0"
                ></div>
                <div v-for="(item, index) in goodsList" :key="index">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>{{
                            item.goodsLevel.level1Name
                        }}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{
                            item.goodsLevel.level2Name
                        }}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{
                            item.goodsLevel.level3Name
                        }}</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="goods-detail-box">
                        <div class="detail-box-top">
                            <div class="detail_img">
                                <!-- <img v-if="homePicture" :src="homePicture" alt="" /> -->
                                <el-carousel
                                    ref="carousel"
                                    :autoplay="false"
                                    :interval="3000"
                                    style="width: 450px;"
                                    indicator-position="outside"
                                    height="450px"
                                    v-if="homePicture && homePicture.length"
                                >
                                    <el-carousel-item
                                        v-for="(item) in homePicture"
                                        :key="item"
                                    >
                                        <img :src="item" alt="" />
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <div class="detail-content">
                                <div class="name">
                                    <span
                                        class="title_name"
                                        v-show="item.sourceType == 0"
                                        >自营</span
                                    >
                                    <span
                                        class="title_name"
                                        v-show="item.sourceType == 1"
                                        >京东</span
                                    >
                                    <span
                                        class="title_name"
                                        v-show="item.sourceType == 2"
                                        >震坤行</span
                                    >
                                    <span
                                        class="title_name"
                                        v-show="item.sourceType == 3"
                                        >怡亚通</span
                                    >
                                    {{ item.name }}
                                </div>
                                <div
                                    class="goods-sku"
                                    v-show="item.sourceType == 1"
                                >
                                    <div class="good-item">
                                        京东skuId:{{ sourceId }}
                                    </div>
                                    <span
                                        class="iconfont icon-fuzhi1"
                                        @click="handleCopy(sourceId)"
                                    ></span>
                                </div>
                                <div
                                    class="goods-sku"
                                    v-show="item.sourceType == 2"
                                >
                                    <div class="good-item">
                                        震坤行skuId:{{ sourceId }}
                                    </div>
                                    <span
                                        class="iconfont icon-fuzhi1"
                                        @click="handleCopy(sourceId)"
                                    ></span>
                                </div>
                                <div class="goods-price">
                                    <div
                                        class="market_price"
                                        v-show="inventoryDisplay"
                                    >
                                        ¥{{ price
                                        }}<span v-show="price < marketPrice"
                                            >¥{{ marketPrice }}</span
                                        >
                                    </div>
                                    <div
                                        class="market_price"
                                        v-show="!inventoryDisplay"
                                    >
                                        {{ priceRangeStr }}
                                    </div>
                                </div>

                                <GoodsSku
                                    :goodsData="item.productSkuStock"
                                    @change="goodsSkuChange"
                                ></GoodsSku>
                                <!-- <div
                  class="goods_hue"
                  v-for="(item, index) in specificationAttribute"
                  :key="index"
                >
                  <div class="title">{{ item }}：</div>
                  <div style="width: 85%; display: flex; flex-flow: wrap">
                    <div
                      :class="
                          items.disabled
                            ? 'disabledishow'
                            : specsArr[item] === items.value
                            ? 'size_boxs'
                            : 'size_box'
                      "
                      v-for="(items, indexs) in specificationValue[item]"
                      :key="indexs"
                      @click="goodsId(items, item, items.disabled)"
                    >
                      <div v-if="items.value && specsArr[item] === items.value" class="boxs-check"></div>
                      <div v-show="items.value" class="flex-center">{{ items.value }}</div>
                    </div>
                  </div>
                </div> -->

                                <div class="split-line"></div>
                                <div class="address_box flex-start">
                                    <span>配送至：</span>
                                    <el-popover
                                        width="400"
                                        trigger="click"
                                        ref="popover"
                                    >
                                        <div>
                                            <p>配送至</p>
                                            <div class="addressList">
                                                <p
                                                    @click="
                                                        ;(addressName = item) &&
                                                            $refs.popover[0].handleBlur()
                                                    "
                                                    class="item"
                                                    v-for="(item,
                                                    index) in addressList"
                                                    :key="index"
                                                >
                                                    {{ item }}
                                                </p>
                                            </div>
                                            <div
                                                style="border-top: 1px solid #ddd; margin: 10px 0"
                                            ></div>
                                            <el-cascader
                                                :options="options"
                                                ref="cascaderAddr"
                                                v-model="selectAddress"
                                                @change="chooseAddress"
                                                placeholder="选择新地址"
                                            >
                                            </el-cascader>
                                        </div>

                                        <div class="address" slot="reference">
                                            <span
                                                class="iconfont icon-address"
                                            ></span>
                                            <span>{{ addressName }}</span>
                                            <span
                                                class="iconfont icon-zhankai"
                                            ></span>
                                        </div>
                                    </el-popover>
                                    <span
                                        v-if="isPurchaseRestriction != 0"
                                        style="margin-right: 10px"
                                        >限购:{{ isPurchaseRestriction }}</span
                                    >
                                    <span
                                        v-show="
                                            item.sourceType == 1 &&
                                                inventoryDisplay
                                        "
                                        style="margin-right: 10px"
                                        >{{ stock > 0 ? '有货' : '没货' }}</span
                                    >
                                    <span
                                        v-show="
                                            item.sourceType == 0 &&
                                                inventoryDisplay
                                        "
                                        >{{
                                            stock > 0
                                                ? '剩余' + stock + '件'
                                                : '没货'
                                        }}
                                    </span>
                                    <span
                                        v-show="
                                            item.sourceType == 2 &&
                                                inventoryDisplay
                                        "
                                        >{{ stock > 0 ? '有货' : '没货' }}
                                    </span>
                                </div>
                                <el-input-number
                                    v-model="num"
                                    @change="handleChange"
                                    :min="initialPurchaseNums"
                                    :disabled="initialPurchaseNums == null"
                                    :max="maxNum"
                                    :maxlength="4"
                                    label="描述文字"
                                ></el-input-number>
                                <div class="btn-groups flex-start">
                                    <el-button
                                        type="warning"
                                        @click="joinShopCart(item)"
                                        :disabled="
                                            stock == 0 || !authorityJudgment(3)
                                        "
                                        >加入购物车</el-button
                                    >
                                    <el-button
                                        type="warning"
                                        @click="gotoclose(item.id, item.kind)"
                                        :disabled="
                                            stock == 0 || !authorityJudgment(3)
                                        "
                                        >立即购买</el-button
                                    >
                                </div>
                                <!-- <div class="delivery-warn">
                  <span>温馨提示：asdfasdfsdadfsdfasdfasdfsdfsadfsdf</span>
                  <marquee>温馨提示：asdfasdfsdadfsdfasdfasdsdfsdadfsdfasdfasdsdfsdadfsdfasdfasdfsdfsadfsdf</marquee>
                </div> -->
                                <div v-if="warmPrompt" class="delivery-warn">
                                    <marquee
                                        >温馨提示：{{ warmPrompt }}</marquee
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="nav-box flex-start">
                            <div class="nav-item">商品介绍</div>
                        </div>
                        <!-- <div class="detail-content-box" v-if="detailInformation" v-html="detailInformation" @click="clickHandle"></div> -->
                        <div
                            class="detail-content-box"
                            v-html="item.detailInformation"
                            @click="clickHandle"
                        ></div>
                        <!-- <div class="detail-content-box" @click="clickHandle">
              <div class="p-list">
                  <div class="list-item">品牌：晗沫美衣</div>
                  <div class="list-item-title">商品名称：晗沫美衣 针织衫2021秋冬韩版金丝小香风格子慵懒风宽松毛衣外套女针织开衫 HMZZ604 黑色 均码</div>
                  <div class="list-item">商品编码：100023429014</div>
                  <div class="list-item">上架时间：</div>
                  <div class="list-item">商品毛重：0.200 kg</div>
                  <div class="list-item">商品产地：浙江台州</div>
                </div>
                <div style="text-align:center;">
                  <img src="@/assets/image/01.jpg" alt="">
                </div>
            </div> -->
                        <div v-html="sysOptiona"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="3" style="width: 15.5%"></el-col>
        </el-row>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import GoodsSku from './workbench/components/goodsSku.vue'
import { goodsDetails, joinShopCart, sysOptionalConfigQuery } from '@/api/api'
import {
    getAddress,
    restaddress,
    findDeliveryRuleByGoods,
} from '@/api/order/order'
let pcas = require('@/assets/json/pca-code.json')
import { authorityJudgment } from '@/utils/util'

// import axios from "axios";
export default {
    components: { Header, Footer, GoodsSku },
    data() {
        return {
            loading: true,
            spliter: '\u2299',
            authorityJudgment: authorityJudgment,
            number: '1',
            num: 0,
            id: '',
            specificationAttribute: [], // 规则名数组
            specificationValue: [], //  规格值数组
            goodsList: [],
            value: '',
            valueName: [],
            skuId: '',
            initialPurchaseNums: 0, //起购数量
            specsArr: {}, //规格
            // stock: 1,
            isPurchaseRestriction: 1, //当前规格的限购数量
            sourceType: 0, //当前商品类型 0-本地 1-京东 2-京东
            price: '',
            matchingQuantity: {},
            name: '',
            address: '',
            addressId: '',
            isDefault: 0,
            addressName: '', //选中的地址名称
            defaultAddress: {}, //默认地址
            localArress: {}, //本地地址
            addressList: [],
            selectAddress: '', //选择新的地址
            city: '',
            district: '',
            province: '',
            options: pcas,
            marketPrice: '',
            sourceId: '',
            homePicture: [],
            skuHomePicture: '',
            sysOptiona: '',
            warmPrompt: null, // 温馨提示
            inventoryDisplay: false, // 库存价格显示隐藏变量
            priceRangeStr: '', // 价格区间显示字符串
        }
    },
    mounted() {
        findDeliveryRuleByGoods(this.$route.query.id).then((res) => {
            if (res.code == '00000') {
                this.warmPrompt = res.data
            }
        })
        sysOptionalConfigQuery(2).then((res) => {
            if (res.code == '00000') {
                this.sysOptiona = res.data
            }
        })
        // this.getLocation(); //获取本地地址
        this.getAddress() //获取默认地址
        this.restaddress() //获取其他地址
    },
    onComplete(data) {
        console.log(data, 88989)
    },
    computed: {
        //当前规格的库存
        stock() {
            // console.log(this.goodsList, "goodsList");
            //0就获取stock 1就获取isInventoryVO
            let num = 0
            // console.log(this.skuId, "this.skuId");
            this.goodsList[0].productSkuStock.forEach((item) => {
                // console.log(item.id);
                if (this.skuId == item.id) {
                    // console.log('asdjaklsdsakljdsalkjdsalk');
                    if (this.sourceType === 0) {
                        num = item.stock || 0
                    } else {
                        num = item.isInventoryVO === null ? 0 : 9999999999999
                    }
                }
            })
            return num
        },
        //购买数量限制
        maxNum() {
            //做到这里 等待选择规格的！！！！！！！！！！！！！！！！
            let num
            let num1 = this.isPurchaseRestriction //限购
            let num2 = this.stock //库存
            // console.log(num1, num2);
            //限购有值就要做限购，就要和库存比较，不然就直接返回库存
            if (num1) {
                num = num1 > num2 ? num2 : num1
            } else {
                num = num2
            }
            // console.log(num, "可购买的数量");
            return num
        },
    },
    watch: {
        addressName() {
            this.getData()
        },
    },
    methods: {
        //地址选择
        chooseAddress(e) {
            let arr = pcas
            let address = ''
            e.forEach((item) => {
                let tempArr = arr.filter((items) => items.value == item)
                if (tempArr.length != 0) {
                    address += tempArr[0].label
                }
                arr = tempArr.length != 0 ? tempArr[0].children : []
            })
            // console.log(address, "address");
            this.addressName = address
            this.$refs.popover[0].handleBlur()
        },
        getLocation() {
            this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
                key: 'UAJBZ-M7B3F-3OSJ7-JKXF2-UHLCK-XTBBK', //腾讯key（我的测试key）
                output: 'jsonp',
            }).then((res) => {
                // console.log(res, "resres");
                if (res.status === 0) {
                    let { city, district, province } = res.result.ad_info
                    this.city = city
                    this.district = district
                    this.province = province
                    this.localArress = res.result.ad_info
                    this.addressName = province + city + district
                    this.addressList.push(this.addressName)
                } else {
                    this.addressName = '北京市朝阳区天安门'
                    this.addressList.push(this.addressName)
                }
            }).catch(err => {
                console.log(err.message);
                this.addressName = '北京市朝阳区天安门'
                this.addressList.push(this.addressName)
            })
        },
        //获取地址
        getAddress() {
            let data = {
                addressInfo: '',
                id: '',
                labelName: '',
            }
            getAddress(data)
                .then((data) => {
                    if (
                        data.data &&
                        data.code === '00000' &&
                        data.data.id &&
                        data.data.isDefault
                    ) {
                        this.name = data.data.receiveName
                        this.defaultAddress = data.data
                        if (data.data.address != null) {
                            this.addressName =
                                data.data.province +
                                data.data.city +
                                data.data.area +
                                data.data.address
                        } else {
                            this.addressName =
                                data.data.province +
                                data.data.city +
                                data.data.area
                        }
                        this.addressList.push(this.addressName)
                        this.addressId = data.data.id
                        this.isDefault = data.data.isDefault
                    } else {
                        this.getLocation() //获取本地地址
                    }
                })
                .catch((_) => {
                    console.log(_.message)
                    this.getLocation() //获取本地地址
                })
        },
        restaddress() {
            let data = {
                addressInfo: '',
                id: '',
                labelName: '',
            }
            restaddress(data).then((data) => {
                // console.log(data, "data");
                if (data && data.code === '00000') {
                    this.otherAddressList = data.data
                    let arr = data.data.map((item) => {
                        return item.province + item.city + item.area
                    })
                    // console.log(arr, "arr");
                    this.addressList = [...this.addressList, ...arr]
                    this.addressList = [...new Set(this.addressList)]
                }
            })
        },
        //加入购物车
        joinShopCart(item) {
            console.log(item)
            // console.log(this.price);
            if (this.num > this.maxNum) {
                this.$message({
                    message: '商品数量不能超过限购数量或库存',
                    type: 'error',
                })
                return
            }
            let data = {
                nums: this.num,
                enterPrice: this.price,
                skuId: this.skuId,
                goodsAddress: this.addressName,
            }
            let sel = item.productSkuStock.find((item) => item.id == this.skuId)
            // console.log(sel);
            if (sel.externalGoodsId) {
                data = `nums=${this.num}&enterPrice=${this.price}&skuId=${this.skuId}&goodsAddress=${this.addressName}&externalGoodsId=${sel.externalGoodsId}`
            } else {
                data = `nums=${this.num}&enterPrice=${this.price}&skuId=${this.skuId}&goodsAddress=${this.addressName}`
            }
            joinShopCart(data).then((res) => {
                if (res && res.code === '00000') {
                    this.$message({
                        message: '已加入购物车！',
                        type: 'success',
                    })
                } else {
                    this.$message({
                        message: '加入购物车失败！',
                        type: 'error',
                    })
                }
            })
        },
        handleChange(value) {
            if (value == undefined) {
                this.num = null
            }
        },
        gotoclose(goodsid, kind) {
            if (this.num > this.maxNum) {
                this.$message({
                    message: '商品数量不能超过限购数量或库存',
                    type: 'error',
                })
                return
            }
            if (this.skuId === '') {
                this.$message({
                    message: '请先选中规格',
                    type: 'error',
                })
            } else {
                sessionStorage.setItem('multipleSelection', '')
                this.$router.push({
                    name: 'GoodsClose',
                    query: {
                        goodsid: goodsid,
                        skuid: this.skuId,
                        number: this.num,
                        kind: kind,
                    },
                })
            }
        },
        // sku change
        goodsSkuChange(selected) {
            console.log('change', selected.skuId)
            if (selected.skuId) {
                // 规格全选的时候走的逻辑
                this.inventoryDisplay = true // 库存价格显示
                this.goodsList[0].productSkuStock.forEach((item) => {
                    if (item.id == selected.skuId) {
                        // console.log(item);
                        this.skuId = item.id
                        this.initialPurchaseNums = item.initialPurchaseNums || 1
                        this.num = this.initialPurchaseNums
                        this.price = item.price
                        this.marketPrice = item.marketPrice
                        this.sourceId = item.sourceId
                        if(this.skuHomePicture) {
                          this.homePicture[0] = item.pic
                        } else {
                          this.homePicture.unshift(item.pic)
                        }
                        this.$nextTick(() => {
                          this.$refs.carousel[0].setActiveItem(this.homePicture.length - 1)
                        })
                        this.skuHomePicture = item.pic
                    }
                })
            } else {
                this.inventoryDisplay = false // 库存价格隐藏
                this.skuId = ''
                this.initialPurchaseNums = 0
                this.num = this.initialPurchaseNums
                this.price = ''
                this.marketPrice = ''
                this.sourceId = ''
            }
        },
        getData() {
            //   this.loading = true
            this.id = this.$route.query.id
            goodsDetails({
                id: this.id,
                address: this.addressName,
            })
                .then((res) => {
                    this.loading = false
                    if (res && res.code === '00000') {
                        // console.log(res.data)
                        this.isPurchaseRestriction =
                            res.data.isPurchaseRestriction || 0
                        this.sourceType = res.data.sourceType
                        this.goodsList = [res.data]
                        this.homePicture.push(res.data.homePicture)
                        let imgs = res.data.detailPictures[0].split(';')
                        if (imgs.length > 0) {
                            imgs.forEach((url, i) => {
                                if (i != imgs.length - 1) {
                                    this.homePicture.push(url)
                                }
                            })
                        }
                        let priceArr = res.data.productSkuStock.map(
                            (item) => item.price
                        )
                        let min = Math.min.apply(null, priceArr)
                        let max = Math.max.apply(null, priceArr)
                        if(min == max){
                            this.priceRangeStr = '￥' + min
                        } else {
                            this.priceRangeStr = '￥' + min + ' ~ ' + max
                        }
                            
                    } else {
                        this.$message({
                            message: res.desc,
                            type: 'error',
                        })
                        this.$router.push({ name: 'Index' })
                    }
                })
                .catch( _ => {
                    console.log(_.message)
                    this.loading = false
                })
        },
        handleCopy(item) {
            let data = item
            let oInput = document.createElement('input')
            oInput.value = data
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand('Copy')
            this.$message({
                message: '复制成功',
                type: 'success',
            })
        },
        // v-html img点击
        clickHandle(e) {
            if (
                (e.target.nodeName == 'IMG' || e.target.nodeName == 'img') &&
                e.target.dataset?.href
            ) {
                console.log(e.target.dataset)
                window.open(e.target.dataset.href)
            }
        },
    },
}
</script>

<style lang="scss">
.addressList {
    .item {
        margin-top: 5px;
    }
    .item:hover {
        cursor: pointer;
        color: #f2270c;
    }
}
.goods-detail {
    .el-breadcrumb {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        .el-breadcrumb__inner {
            color: #cd9f49;
        }
        .el-breadcrumb__separator {
            color: #cd9f49;
        }
    }
    .goods-detail-box {
        margin-bottom: 30px;
    }
    .detail-box-top {
        display: flex;
        .detail_img {
            width: 452px;
            height: 452px;
            border: 1px solid #ebebeb;
            margin-bottom: 20px;
            img {
                width: 450px;
                height: 450px;
            }
        }
        .detail-content {
            width: 70%;
            margin-left: 30px;
            .title_name {
                color: #fff;
                background: #cd9f49;
                padding: 2px 5px;
                font-size: 15px;
                border-radius: 4px;
            }
            .name {
                font-size: 18px;
                margin-bottom: 20px;
            }
            .goods-sku {
                height: 40px;
                line-height: 40px;
                padding-left: 15px;
                background-color: #f9f9f9;
                display: flex;
                .good-item {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
            .goods-price {
                padding: 7px 15px;
                height: 26px;
                background-color: #f4f4f4;
                margin-bottom: 20px;
                .market_price {
                    font-size: 20px;
                    color: #f2270c;
                    span {
                        color: #797979;
                        font-size: 14px;
                        margin-left: 10px;
                        text-decoration: line-through;
                    }
                }
            }

            .goods_hue {
                margin-bottom: 20px;
                display: flex;

                .title {
                    flex-shrink: 0;
                }
                .size_box {
                    // width: 39%;
                    display: flex;
                    border: 1px solid #e5e5e5;
                    width: auto;
                    height: 42px;
                    font-size: 14px;
                    margin: 1px 8px 15px 0;
                    position: relative;
                    top: 1px;
                    left: 1px;
                    cursor: pointer;
                    &:hover {
                        border: 1px solid red;
                    }
                    img {
                        width: 42px;
                        height: 42px;
                    }
                    div {
                        padding: 0 8px;
                        font-size: 14px;
                        text-align: center;
                        // line-height: 42px;
                    }
                }
                .size_boxs {
                    // width: 39%;
                    display: flex;
                    border: 1px solid red;
                    width: auto;
                    height: 42px;
                    font-size: 14px;
                    margin: 1px 8px 15px 0;
                    position: relative;
                    top: 1px;
                    left: 1px;
                    overflow: hidden;
                    cursor: pointer;
                    img {
                        width: 42px;
                        height: 42px;
                    }
                    div {
                        padding: 0 8px;
                        font-size: 14px;
                        text-align: center;
                        // line-height: 42px;
                    }
                    .boxs-check {
                        position: absolute;
                        top: 0;
                        left: -16px;
                        color: #fff;
                        background-color: #f2270c;
                        width: 38px;
                        height: 16px;
                        transform: rotate(315deg);
                        padding: 0;
                        &::after {
                            content: '';
                            background: transparent;
                            width: 8px;
                            height: 4px;
                            border-left: 2px solid #fff;
                            border-bottom: 2px solid #fff;
                            position: absolute;
                            top: 6px;
                            left: 18px;
                        }
                    }
                }
                .disabledishow {
                    // width: 39%;
                    display: flex;
                    border: 1px solid #e5e5e5;
                    width: auto;
                    height: 42px;
                    background-color: #eee;
                    color: #ccc;
                    font-size: 14px;
                    margin: 1px 8px 15px 0;
                    position: relative;
                    top: 1px;
                    left: 1px;
                    div {
                        padding: 0 8px;
                        font-size: 14px;
                        text-align: center;
                        // line-height: 42px;
                    }
                }
            }
            .goods_size {
                margin-bottom: 20px;
                .size_box {
                    border: 1px solid #e5e5e5;
                    width: 42px;
                    height: 42px;
                    font-size: 14px;
                    text-align: center;
                    line-height: 42px;
                }
            }
            .split-line {
                margin: 11px 0;
                border-top: 2px dotted #dfdfdf;
            }
            .address_box {
                margin-bottom: 20px;
                height: 54px;
                .address {
                    padding: 3px 8px;
                    border: 1px solid #ddd;
                    margin-right: 20px;
                }
            }
            .btn-groups {
                margin-top: 20px;
                .el-button {
                    font-size: 16px;
                    padding-left: 32px;
                    padding-right: 32px;
                }
            }
            .delivery-warn {
                margin-top: 20px;
                height: 35px;
                line-height: 35px;
                background-color: #eb2f3d;
                padding: 0 14px;
                color: #fff;
                // width: 70%;
                overflow: hidden;
                position: relative;

                span {
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    white-space: nowrap;
                    // transform:translateX(0px);
                    animation: cssmarquee 20s linear infinite normal;
                }
            }
            @keyframes cssmarquee {
                0% {
                    left: 0;
                }
                50% {
                    left: -100%;
                }
                51% {
                    left: 100%;
                }
                100% {
                    left: 0;
                }
            }
        }
    }

    .nav-box {
        height: 37px;
        line-height: 37px;
        background: #f8f8f8;
        border-bottom: 1px solid #cd9f49;
        .nav-item {
            height: 100%;
            line-height: 36px;
            padding: 0 25px;
            font-size: 14px;
            cursor: pointer;
            background: #cd9f49;
            color: #fff;
            border-radius: 2px 2px 0 0;
        }
    }
    .detail-content-box {
        padding: 15px 18px;
        line-height: 40px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        .p-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .list-item {
                width: 288px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .list-item-title {
                width: 865px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        img {
            display: block;
            margin: 0 auto;
        }
    }
}
</style>
